import styled from '@emotion/styled'
import { breakpoints } from '../../utils/styles'

export const Wrapper = styled.div`
  @media (min-width: ${breakpoints.m}px) {
    max-width: 1000px;
    margin: 0 auto 100px;
  }
`

export const Title = styled.div`
  text-align: center;
  margin-bottom: 20px;
`

export const EnLabel = styled.div`
  font-size: 15px;
`

export const Label = styled.div`
  font-size: 22px;
`

export const LinkCardWrap = styled.div`
  display: flex;
  flex-wrap: wrap;
`
