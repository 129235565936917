import React, { useContext } from 'react'
import { ImageWrapper, Item, Name, Price, Tail, Wrapper } from './style'
import { graphql, Link, useStaticQuery } from 'gatsby'
import StoreContext from '../../context/StoreContext'
import { Img } from '../../utils/styles'

const PickupItemList = () => {
  const { allShopifyProduct } = useStaticQuery(
    graphql`
      query {
        allShopifyProduct(
          sort: { fields: [createdAt], order: DESC }
          filter: { tags: { in: "Pickup" } }
        ) {
          edges {
            node {
              tags
              id
              title
              handle
              shopifyId
              createdAt
              images {
                id
                originalSrc
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 910) {
                      ...GatsbyImageSharpFluid_withWebp_tracedSVG
                    }
                  }
                }
              }
              variants {
                price
              }
            }
          }
        }
      }
    `
  )

  const {
    store: { checkout },
  } = useContext(StoreContext)

  const getPrice = price =>
    Intl.NumberFormat(undefined, {
      currency: checkout.currencyCode ? checkout.currencyCode : 'EUR',
      style: 'currency',
    }).format(parseFloat(price ? price : 0))

  return (
    <Wrapper>
      {allShopifyProduct.edges ? (
        allShopifyProduct.edges.map(
          ({
            node: {
              id,
              handle,
              shopifyId,
              title,
              images,
              variants: [firstVariant],
            },
          }) => (
            <Item key={id}>
              <Link to={`/product/${shopifyId}/`}>
                <ImageWrapper>
                  {images[1] && images[1].localFile && (
                    <Img
                      fluid={images[1].localFile.childImageSharp.fluid}
                      alt={handle}
                    />
                  )}
                </ImageWrapper>
                <Tail>
                  <Name>{title}</Name>
                  <Price>{getPrice(firstVariant.price)}</Price>
                </Tail>
              </Link>
            </Item>
          )
        )
      ) : (
        <p>no item</p>
      )}
    </Wrapper>
  )
}

export default PickupItemList
