import { Link } from 'gatsby'
import React from 'react'
import { EnLabel, Label, Title, Wrapper } from './style'
import Image from '../image'
import Scale from '../Scale'

export const LinkCard = ({ path, img, en, label }) => {
  return (
    <Wrapper>
      <Link to={path}>
        <Image filename={img} />
        <Scale opacity={0.3} />
        <Title>
          <EnLabel>{en}</EnLabel>
          <Label>{label}</Label>
        </Title>
      </Link>
    </Wrapper>
  )
}
