import styled from '@emotion/styled'
import { breakpoints } from '../utils/styles'

export const Head = styled.div`
  position: relative;

  @media (min-width: ${breakpoints.m}px) {
    height: 65vh;
    overflow: hidden;
  }
`

export const TextContainer = styled.div`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  right: 0;
  margin: 0 auto;
  padding: 0 24px;
`

export const Text = styled.div`
  font-size: 14px;
  color: #fff;
  text-align: center;

  @media (min-width: ${breakpoints.m}px) {
    font-size: 18px;
  }
`
