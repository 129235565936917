import styled from '@emotion/styled'
import colors from '../../styles/color'

import { breakpoints } from '../../utils/styles'

export const Wrapper = styled.div`
  margin-bottom: 80px;
  @media (min-width: ${breakpoints.m}px) {
    max-width: 1000px;
    margin: 0 auto;
  }
`

export const LinkRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 5px 0;
  overflow: scroll;
`

export const LinkRowItem = styled.div`
  flex-shrink: 0;
  background-color: ${colors.WHITE_GRAY};
  padding: 5px 8px;
  font-size: 13px;
  margin-right: 4px;
  a {
    color: ${colors.BLACK};
    text-decoration: none;

    @media (min-width: ${breakpoints.m}px) {
      font-size: 20px;
    }
  }
`

export const Grid = styled.div`
  display: flex;
  flex-wrap: wrap;
  background-color: #f7f7f7;
  margin-bottom: 80px;
`

export const Product = styled.div`
  // borderのwidth分引く
  width: calc(50% - 1px);

  padding: 8px;
  border-bottom: 1px solid #fff;
  border-right: ${props =>
    props.rowNum % 2 === 0 ? '1px solid #fff' : 'none'};
`

export const Tail = styled.div`
  text-align: center;
  font-weight: 700;
`

export const Title = styled.h3`
  font-size: 12px;
  color: #707070;
  @media (min-width: ${breakpoints.m}px) {
    font-size: 15px;
  }
`

export const PriceTag = styled.div`
  font-size: 12px;
  color: #707070;

  @media (min-width: ${breakpoints.m}px) {
    font-size: 15px;
  }
`

// export const Grid = styled.div`
//   display: grid;
//   grid-template-columns: repeat(3, 1fr);
//   gap: 2.5rem;
//
//   @media (max-width: ${breakpoints.s}px){
//     grid-template-columns: repeat(1, 1fr);
//   }
// `
//
// export const Product = styled.div`
//   display: flex;
//   min-height: 100%;
//   flex-direction: column;
// `
//
// export const Title = styled.span`
//   font-weight: 300;
//   font-size: 1.2rem;
//   text-align: center;
// `
//
// export const PriceTag = styled.span`
//   font-weight: 300;
//   font-size: 1rem;
//   text-align: center;
//   margin-top: 15px;
//
//   :before {
//     content: '- '
//   }
