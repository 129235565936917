import React from 'react'
import PickupItemList from '../PickupItemList'
import { Wrapper, Title, TitleLabel, TitleName } from './style'

const Recommend = () => {
  return (
    <Wrapper>
      <Title>
        <TitleLabel>Recommend</TitleLabel>
        <TitleName>おすすめの商品</TitleName>
      </Title>
      <PickupItemList />
    </Wrapper>
  )
}

export default Recommend
