import styled from '@emotion/styled'
import { breakpoints } from '../../utils/styles'

export const Wrapper = styled.div`
  margin-bottom: 55px;
  @media (min-width: ${breakpoints.m}px) {
    max-width: 1000px;
    margin: 0 auto 55px;
  }
`

export const Title = styled.div`
  margin-bottom: 26px;
  text-align: center;
`

export const TitleLabel = styled.div`
  font-size: 15px;
`

export const TitleName = styled.h2`
  font-size: 22px;
`
