import React from 'react'
import SEO from '~/components/seo'
import ProductGrid from '../components/ProductGrid'
import Image from '../components/image'
import CategoryTitle from '../components/Title/CategoryTitle'
import { Head, TextContainer } from '../styles/productListPage'
import Scale from '../components/Scale'
import { graphql, useStaticQuery } from 'gatsby'

const ProductListPage = () => {
  const { allShopifyProduct } = useStaticQuery(
    graphql`
      query {
        allShopifyProduct(
          sort: { fields: [createdAt], order: DESC }
          filter: { productType: { eq: "ブレスレット" } }
        ) {
          edges {
            node {
              tags
              id
              title
              handle
              createdAt
              images {
                id
                originalSrc
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 910) {
                      ...GatsbyImageSharpFluid_withWebp_tracedSVG
                    }
                  }
                }
              }
              variants {
                price
              }
            }
          }
        }
      }
    `
  )
  return (
    <>
      <SEO
        title="商品一覧"
        keywords={[`高天原`, `アクセサリー`, `天然石`, `accessory`, `stone`]}
      />
      <Head>
        <Image filename="photo/site-image.jpg" />
        <Scale />
        <TextContainer>
          <CategoryTitle en="Product" label="商品一覧" color="white" />
        </TextContainer>
      </Head>
      <ProductGrid data={allShopifyProduct.edges} />
    </>
  )
}

export default ProductListPage
