import React, { useContext } from 'react'
import { Link } from 'gatsby'
import StoreContext from '~/context/StoreContext'
import {
  Grid,
  Product,
  Title,
  PriceTag,
  Tail,
  Wrapper,
  LinkRow,
  LinkRowItem,
} from './styles'
import Recommend from '../Recommend'
import CategoryList from '../CategoryLink'
import { Img } from '../../utils/styles'

const ITEM_LIST = [
  {
    path: '/bracelet-list',
    name: 'ブレスレット',
  },
  {
    path: '/designbracelet-list',
    name: 'デザインブレスレット',
  },
  {
    path: '/necklace-list',
    name: 'ネックレス',
  },
  {
    path: '/stone-list',
    name: '原石',
  },
  {
    path: '/item-list',
    name: '雑貨',
  },
]

/**
 *
 * @param data {Object} allShopifyProduct
 */
export const ProductGrid = ({ data }) => {
  // TODO: logicsに共通化
  const {
    store: { checkout },
  } = useContext(StoreContext)

  const getPrice = price =>
    Intl.NumberFormat(undefined, {
      currency: checkout.currencyCode ? checkout.currencyCode : 'EUR',
      style: 'currency',
    }).format(parseFloat(price ? price : 0))

  return (
    <>
      <Wrapper>
        <LinkRow>
          {ITEM_LIST.map(({ path, name }) => (
            <LinkRowItem>
              <Link to={path}>{name}</Link>
            </LinkRowItem>
          ))}
        </LinkRow>
        <Grid>
          {data ? (
            data.map(
              (
                {
                  node: {
                    tags,
                    id,
                    handle,
                    shopifyId,
                    title,
                    images: [firstImage],
                    variants: [firstVariant],
                  },
                },
                index
              ) => (
                <Product key={id} rowNum={index}>
                  <Link to={`/product/${shopifyId}/`}>
                    {firstImage && firstImage.localFile && (
                      <Img
                        fluid={firstImage.localFile.childImageSharp.fluid}
                        alt={handle}
                      />
                    )}
                  </Link>
                  <Tail>
                    <Title>{title}</Title>
                    <PriceTag>{getPrice(firstVariant.price)}</PriceTag>
                  </Tail>
                </Product>
              )
            )
          ) : (
            <p>No Products found!</p>
          )}
        </Grid>
        <Recommend />
      </Wrapper>
      <CategoryList />
    </>
  )
}

export default ProductGrid
