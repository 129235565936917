import styled from '@emotion/styled'
import colors from '../../styles/color'
import { breakpoints } from '../../utils/styles'

export const Wrapper = styled.div`
  position: relative;
  overflow: hidden;
  height: 200px;
  width: 100%;
  @media (min-width: ${breakpoints.m}px) {
    width: 50%;
    height: 300px;
  }
`

export const Title = styled.div`
  position: absolute;
  top: 16px;
  left: 16px;
  color: ${colors.WHITE};
`

export const EnLabel = styled.div`
  font-family: linotype-didot, serif;
  font-size: 15px;
`

export const Label = styled.div`
  font-size: 20px;
`
