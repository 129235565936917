import React from 'react'
import { Wrapper, EnLabel, Label, Title, LinkCardWrap } from './style'
import { LinkCard } from '../LinkCard'

const LIST = [
  {
    en: 'Bracelet',
    label: 'ブレスレット',
    path: '/bracelet-list',
    img: 'photo/c_bracelet.jpg',
  },
  {
    en: 'Design Bracelet',
    label: 'デザインブレスレット',
    path: '/designbracelet-list',
    img: 'photo/c_design_bracelet_2.jpg',
  },
  {
    en: 'Necklace',
    label: 'ネックレス',
    path: '/necklace-list',
    img: 'photo/c_necklace.jpg',
  },
  {
    en: 'Stone',
    label: '原石',
    path: '/stone-list',
    img: 'photo/stone.jpg',
  },
]

const CategoryLink = () => {
  return (
    <Wrapper>
      <Title>
        <EnLabel>Category</EnLabel>
        <Label>カテゴリー</Label>
      </Title>
      <LinkCardWrap>
        {LIST.map(({ en, label, path, img }) => (
          <LinkCard en={en} label={label} path={path} img={img} />
        ))}
      </LinkCardWrap>
    </Wrapper>
  )
}

export default CategoryLink
