import styled from '@emotion/styled'
import colors from '../../styles/color'

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  overflow: scroll;
  overflow-scrolling: touch;
`

export const Item = styled.div`
  margin-right: 2px;
`

export const ImageWrapper = styled.div`
  width: 200px;
  margin-bottom: 4px;
`

export const Tail = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 15px;
  padding: 0 4px;
`

export const Name = styled.h3`
  color: ${colors.BLACK};
  font-size: 14px;
  font-weight: normal;
  text-align: center;
  min-height: 42px;
  display: flex;
  align-items: center;
`

export const Price = styled.div`
  font-size: 14px;
  color: ${colors.BLACK};
`
